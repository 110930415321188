import { RpcListMethods } from '@gain/rpc/cms-model'
import { ListFilter } from '@gain/rpc/list-model'
import { ChipOption, Option } from '@gain/rpc/shared-model'
import { formatDate } from '@gain/utils/date'
import {
  GridActionsColDef,
  GridColDef,
  GridColTypeDef,
  GridSingleSelectColDef,
  GridValueFormatter,
} from '@mui/x-data-grid/models/colDef/gridColDef'
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColType,
} from '@mui/x-data-grid-pro'
import { useMemo } from 'react'

import { SwrDataGridProps } from '../swr-data-grid'
import { renderAvatarCell } from './columns/avatar'
import { renderChipOptionsCell } from './columns/chip-options'
import { renderOptionsEditCell, SwrDataGridOptionsFilter } from './columns/options'
import { isOptionsColumn } from './columns/options/swr-data-grid-options-utils'
import SwrListMethodFilter from './filters/swr-list-method-filter.component'
import { swrOptionsFilters } from './filters/swr-options-filter'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GridValidRowModel = { [key: string]: any }
type RowValue<Row extends GridValidRowModel> = Row[keyof Row]

interface SwrDataGridColumnBase<Row extends GridValidRowModel, Type>
  extends Omit<GridColDef<Row, RowValue<Row>>, 'valueFormatter' | 'field' | 'type'> {
  field: keyof Row | string
  type?: Type | string
  // Overwrite typing so we can correctly type it in the places we use this with correct values
  valueFormatter?: GridValueFormatter<Row, RowValue<Row>, RowValue<Row> | undefined>
}

interface SwrGridActionsColDef<Row extends GridValidRowModel>
  extends Omit<GridActionsColDef<Row, RowValue<Row>>, 'field'> {
  field: 'actions'
}

interface SwrGridSingleSelectColDef<Row extends GridValidRowModel>
  extends Omit<GridSingleSelectColDef<Row, RowValue<Row>>, 'field'> {
  field: keyof Row
}

export type SwrDataGridColumn<Row extends GridValidRowModel> =
  | SwrGridActionsColDef<Row>
  | SwrGridSingleSelectColDef<Row>
  | SwrDataGridColumnBase<Row, GridColType>
  | SwrDataGridColumnBase<Row, 'actions'>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | SwrDataGridColumnListMethod<Row, any>
  | SwrDataGridColumnOptions<Row>
  | SwrDataGridColumnChipOptions<Row>

export interface SwrDataGridColumnListMethod<
  Row extends GridValidRowModel,
  Method extends keyof RpcListMethods
> extends SwrDataGridColumnBase<Row, 'listMethod'> {
  method: Method
  labelProp: keyof RpcListMethods[Method]
  valueProp: keyof RpcListMethods[Method]
  getOptionLabel?: (item: RpcListMethods[Method]) => string
  defaultMethodFilter?: ListFilter<RpcListMethods[Method]>[]
  allowFilterMultiple?: boolean
}

export interface SwrDataGridColumnOptions<Row extends GridValidRowModel>
  extends SwrDataGridColumnBase<Row, 'options'> {
  multiple?: boolean
  options: ReadonlyArray<Option<RowValue<Row>>>
  allowFilterMultiple?: boolean
  clearable?: boolean
}

export interface SwrDataGridColumnChipOptions<Row extends GridValidRowModel>
  extends SwrDataGridColumnBase<Row, 'chip-options'> {
  options: ChipOption<RowValue<Row>>[]
}

export default function useSwrDataGridColumns<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({ columns }: SwrDataGridProps<Method, Row>): Array<SwrDataGridColumn<Row>> {
  const columnTypes = useMemo(
    (): { [key: string]: GridColTypeDef } => ({
      string: {
        filterOperators: getGridStringOperators().filter((operator) =>
          ['equals'].includes(operator.value)
        ),
      },
      number: {
        filterOperators: getGridNumericOperators().filter((operator) =>
          ['=', '>', '<'].includes(operator.value)
        ),
      },
      dateTime: {
        filterOperators: getGridDateOperators().filter((operator) =>
          ['after', 'before'].includes(operator.value)
        ),
        valueFormatter: (value) => formatDate(value, { format: 'dateTime' }),
      },
      date: {
        filterOperators: getGridDateOperators().filter((operator) =>
          ['after', 'before'].includes(operator.value)
        ),
        valueFormatter: (value) => formatDate(value, { format: 'date' }),
      },
      listMethod: {
        filterOperators: getGridStringOperators().filter((operator) =>
          ['equals'].includes(operator.value)
        ),
        // Only one operator is accepted so overwrite the while header
        renderHeaderFilter: SwrListMethodFilter,
      },
      options: {
        filterOperators: swrOptionsFilters,
        renderHeaderFilter: SwrDataGridOptionsFilter,
        renderEditCell: renderOptionsEditCell,
        valueFormatter: (value, row, column) => {
          if (!isOptionsColumn(column)) {
            return '-'
          }

          return column.options.find((option) => option.value === value)?.label ?? '-'
        },
      },
      'chip-options': {
        filterOperators: swrOptionsFilters,
        renderCell: renderChipOptionsCell,
      },
      avatar: {
        renderCell: renderAvatarCell,
        headerName: '',
        width: 50,
        filterable: false,
      },
      actions: {
        width: 50,
        align: 'right',
      },
    }),
    []
  )

  return useMemo((): SwrDataGridColumn<Row>[] => {
    return columns.map((column) => ({
      ...columnTypes[(column.type as string) || 'string'],
      ...column,
    }))
  }, [columnTypes, columns])
}
